export let threeWindDirections = [
  { windDirection: 0, roughness: 0.5 },
  { windDirection: 120, roughness: 0.5 },
  { windDirection: 240, roughness: 0.5 },
];

export let sixWindDirections = [
  { windDirection: 0, roughness: 0.5 },
  { windDirection: 60, roughness: 0.5 },
  { windDirection: 120, roughness: 0.5 },
  { windDirection: 180, roughness: 0.5 },
  { windDirection: 240, roughness: 0.5 },
  { windDirection: 300, roughness: 0.5 },
];

export let eightWindDirections = [
  { windDirection: 0, roughness: 0.5 },
  { windDirection: 45, roughness: 0.5 },
  { windDirection: 90, roughness: 0.5 },
  { windDirection: 135, roughness: 0.5 },
  { windDirection: 180, roughness: 0.5 },
  { windDirection: 225, roughness: 0.5 },
  { windDirection: 270, roughness: 0.5 },
  { windDirection: 315, roughness: 0.5 }
];

export let sixteenWindDirections = [
  { windDirection: 0, roughness: 0.5 },
  { windDirection: 22.5, roughness: 0.5 },
  { windDirection: 45, roughness: 0.5 },
  { windDirection: 67.5, roughness: 0.5 },
  { windDirection: 90, roughness: 0.5 },
  { windDirection: 112.5, roughness: 0.5 },
  { windDirection: 135, roughness: 0.5 },
  { windDirection: 157.5, roughness: 0.5 },
  { windDirection: 180, roughness: 0.5 },
  { windDirection: 202.5, roughness: 0.5 },
  { windDirection: 225, roughness: 0.5 },
  { windDirection: 247.5, roughness: 0.5 },
  { windDirection: 270, roughness: 0.5 },
  { windDirection: 292.5, roughness: 0.5 },
  { windDirection: 315, roughness: 0.5 },
  { windDirection: 337.5, roughness: 0.5 },
];

export let eighteenWindDirections = [
  { windDirection: 0, roughness: 0.5 },
  { windDirection: 20, roughness: 0.5 },
  { windDirection: 40, roughness: 0.5 },
  { windDirection: 60, roughness: 0.5 },
  { windDirection: 80, roughness: 0.5 },
  { windDirection: 100, roughness: 0.5 },
  { windDirection: 120, roughness: 0.5 },
  { windDirection: 140, roughness: 0.5 },
  { windDirection: 160, roughness: 0.5 },
  { windDirection: 180, roughness: 0.5 },
  { windDirection: 200, roughness: 0.5 },
  { windDirection: 220, roughness: 0.5 },
  { windDirection: 240, roughness: 0.5 },
  { windDirection: 260, roughness: 0.5 },
  { windDirection: 280, roughness: 0.5 },
  { windDirection: 300, roughness: 0.5 },
  { windDirection: 320, roughness: 0.5 },
  { windDirection: 340, roughness: 0.5 },
];

export let thirtySixWindDirections = [
  { windDirection: 0, roughness: 0.5 },
  { windDirection: 10, roughness: 0.5 },
  { windDirection: 20, roughness: 0.5 },
  { windDirection: 30, roughness: 0.5 },
  { windDirection: 40, roughness: 0.5 },
  { windDirection: 50, roughness: 0.5 },
  { windDirection: 60, roughness: 0.5 },
  { windDirection: 70, roughness: 0.5 },
  { windDirection: 80, roughness: 0.5 },
  { windDirection: 90, roughness: 0.5 },
  { windDirection: 100, roughness: 0.5 },
  { windDirection: 110, roughness: 0.5 },
  { windDirection: 120, roughness: 0.5 },
  { windDirection: 130, roughness: 0.5 },
  { windDirection: 140, roughness: 0.5 },
  { windDirection: 150, roughness: 0.5 },
  { windDirection: 160, roughness: 0.5 },
  { windDirection: 170, roughness: 0.5 },
  { windDirection: 180, roughness: 0.5 },
  { windDirection: 190, roughness: 0.5 },
  { windDirection: 200, roughness: 0.5 },
  { windDirection: 210, roughness: 0.5 },
  { windDirection: 220, roughness: 0.5 },
  { windDirection: 230, roughness: 0.5 },
  { windDirection: 240, roughness: 0.5 },
  { windDirection: 250, roughness: 0.5 },
  { windDirection: 260, roughness: 0.5 },
  { windDirection: 270, roughness: 0.5 },
  { windDirection: 280, roughness: 0.5 },
  { windDirection: 290, roughness: 0.5 },
  { windDirection: 300, roughness: 0.5 },
  { windDirection: 310, roughness: 0.5 },
  { windDirection: 320, roughness: 0.5 },
  { windDirection: 330, roughness: 0.5 },
  { windDirection: 340, roughness: 0.5 },
  { windDirection: 350, roughness: 0.5 },
];

export const timeList = {
  0: '12:00 AM',
  1: '1:00 AM',
  2: '2:00 AM',
  3: '3:00 AM',
  4: '4:00 AM',
  5: '5:00 AM',
  6: '6:00 AM',
  7: '7:00 AM',
  8: '8:00 AM',
  9: '9:00 AM',
  10: '10:00 AM',
  11: '11:00 AM',
  12: '12:00 PM',
  13: '1:00 PM',
  14: '2:00 PM',
  15: '3:00 PM',
  16: '4:00 PM',
  17: '5:00 PM',
  18: '6:00 PM',
  19: '7:00 PM',
  20: '8:00 PM',
  21: '9:00 PM',
  22: '10:00 PM',
  23: '11:00 PM',
  24: '12:00 AM'
};

export const monthList = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
};

export const clothingProfileList = [
  'Northern Temperate Casual',
  'Northern Temperate Business Casual',
  'Northern Temperate Business',
  'Northern Moderate Casual',
  'Northern Moderate Business Casual',
  'Northern Moderate Business',
  'Tropical Casual',
  'Tropical Business Casual',
  'Tropical Business',
  'Tropical Beach',
  'Tropical Dining'
];

export const windDirectionList = {
  0: 'N (0°)',
  22.5: 'NNE (22.5°)',
  45: 'NE (45°)',
  67.5: 'ENE (67.5°)',
  90: 'E (90°)',
  112.5: 'ESE (112.5°)',
  135: 'SE (135°)',
  157.5: 'SSE (157.5°)',
  180: 'S (180°)',
  202.5: 'SSW (202.5°)',
  225: 'SW (225°)',
  247.5: 'WSW (247.5°)',
  270: 'W (270°)',
  292.5: 'WNW (292.5°)',
  315: 'NW (315°)',
  337.5: 'NNW (337.5°)'
};
